/* PDTTable.css */

.pdt-table-section {
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensures the section takes full viewport height */
    max-width:100%;
}

.pdt-table {
    width: 100%;
    font-size: large;
    border-collapse: collapse;
    text-align: center;
    color: white;
    font-family: 'Raleway', sans-serif; /* Use Google Fonts for styling */

}

.pdt-table th, .pdt-table td {
    padding: 18px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
}

.pdt-table th {
    font-size: 2rem;
    background-color: #000000;
    color: #ffcc00;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
}

.pdt-table td {
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.pdt-table td:first-child {
    border-left: none;
}

.pdt-table td:last-child {
    border-right: none;
}

.pdt-table tbody tr:hover td {
    background-color: rgba(255, 255, 255, 0.1);
}
