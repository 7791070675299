.home-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  }

  .intro-overlay h3 {
    font-size: 4rem;
  }

@media screen and (max-width: 768px) {
  .home-container {
    position: relative;
    width: 100vw;
    height: 40%;
    margin-top: 8rem;
    overflow: hidden;
  }
  
  .background-image {
    width: 100%;
    height: 40%;
    object-fit: cover;
  }

    .intro-overlay h3 {
        font-size: 1.5rem;
      }
}
  