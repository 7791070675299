@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;1,600&display=swap');

.latest-news-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 100vh;
    background-color: rgb(0, 0, 0);
    color: white;
    padding: 2rem;
    box-sizing: border-box;
    position: relative; /* Added to position the pseudo-element */
}

.latest-news-container::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: white;
    transform: translateX(-50%);
}

.left-section,
.right-section {
    width: calc(50% - 5px); /* Adjusted to ensure the images touch the line */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-section {
    padding: 7rem 0; /* Removed horizontal padding to make images touch the line */
}

.news-content {
    text-align: left;
    margin-bottom: 20px;
    position: relative; /* Added for positioning the overlay */
    width: 100%;
}

.news-content img {
    width: 80%; /* Adjusted to 80% */
    height: auto;
    display: block;
    transition: background-color 0.3s ease-in-out;
    margin: 0 auto; /* Centering the image */
}

.right-section .news-content img,
.left-section .news-content img {
    padding: 0; /* Removed padding to ensure images touch the line */
}

.news-content .img-wrapper {
    position: relative; /* Ensure the wrapper is positioned relative */
    width: 100%; /* Ensure wrapper takes full width of the container */
    display: flex;
    justify-content: center; /* Center the image */
}

.news-content .img-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 10%; /* Adjusted to match image width */
    right: 10%; /* Adjusted to match image width */
    bottom: 0;
    background-color: rgba(255, 255, 0, 0.2); /* Transparent yellow fill */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.news-content:hover .img-wrapper::before {
    opacity: 1;
}

.news-content h2 {
    font-size: 45px;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 5rem;;
}

.news-content p {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: x-large;
    text-align: left;
    margin-bottom: 5rem;
    color: gray;
    font-size: 20px;
    padding-left: 5rem;;
}

.right-section .news-content h2,
.right-section .news-content p,
.left-section .news-content h2,
.left-section .news-content p {
    margin-left: 0rem;
    width: 90%;
}

.mobile-news-container {
    display: none;
}

@media (max-width: 768px) {
    .latest-news-container {
        display: none;
    }

    .mobile-news-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: black;
        color: white;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
    }

    .news-content-wrapper {
        width: 100%;
        position: relative;
        overflow: hidden;
        transition: transform 0.5s ease;
    }

    .mobile-news-container .news-content img {
        width: 100%;
        height: auto;
    }

    .nav-button {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 2rem;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
    }

    .nav-button:hover {
        color: #ff4500;
    }

    .nav-button:first-of-type {
        left: 10px;
    }

    .nav-button:last-of-type {
        right: 10px;
    }

    .news-content h2 {
        font-size: 30px;
        margin-bottom: 10px;
        text-align: left;
        padding: 0;
    }

    .news-content p {
        font-size: 15px;
        padding: 1rem 0;
       
    }
}
